import React from "react";
import PropTypes from "prop-types";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import TextLink from "~components/molecules/text_link/text_link";
import { LINK } from "../../../../prop_types";
import {
  headingStyle,
  sectionWrapperStyle,
} from "./rsc_hb_section_inner.module.scss";

function RscHbSectionInner({ title, children, link, gridSplit, linkTitle }) {
  return (
    <div data-testid="rsc-hb-section-inner" className={sectionWrapperStyle}>
      {title && (
        <div className={headingStyle}>
          {title && (
            <Heading
              data-testid="rsc-hb-section-title"
              visualLevel={5}
              seoLevel={3}
              noMargin
            >
              {title}
            </Heading>
          )}
          {link && (
            <TextLink
              data-testid="rsc-hb-section-link"
              link={link}
              title={linkTitle}
            />
          )}
        </div>
      )}
      <GridNew split={gridSplit}>{children}</GridNew>
    </div>
  );
}

RscHbSectionInner.propTypes = {
  children: PropTypes.node.isRequired,
  gridSplit: PropTypes.shape({}),
  link: LINK,
  linkTitle: PropTypes.string,
  title: PropTypes.string,
};

RscHbSectionInner.defaultProps = {
  gridSplit: { XL: "1" },
  link: null,
  linkTitle: "Explore more",
  title: null,
};

export default RscHbSectionInner;
