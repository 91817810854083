import PropTypes from "prop-types";
import React from "react";

import RscHbPageHeader from "~components/molecules/rsc_hb_page_header/rsc_hb_page_header";

export default function RscHbMultiSectionWrapper({
  title,
  subtitle,
  children,
}) {
  if (title && subtitle && children) {
    return (
      <>
        <RscHbPageHeader title={title} description={subtitle} size="small" />
        {children}
      </>
    );
  }
  return null;
}

RscHbMultiSectionWrapper.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};
RscHbMultiSectionWrapper.defaultProps = {
  title: null,
  subtitle: null,
  children: null,
};
