import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import RscHbCtaNewsletter from "~components/molecules/rsc_hb_cta/rsc_hb_cta_newsletter/rsc_hb_cta_newsletter";
import RscHbInPageContactForm from "~components/molecules/rsc_hb_in_page_contact_form/rsc_hb_in_page_contact_form";
import RscHbPageHeader from "~components/molecules/rsc_hb_page_header/rsc_hb_page_header";
import RscHbPageWrapper from "~components/molecules/rsc_hb_page_wrapper/rsc_hb_page_wrapper";
import usePageData from "~hooks/use_page_data/use_page_data";
import {
  ASSET,
  RESOURCE_HUB_SECTION,
  STORYBLOK_ENTRY,
  STORYBLOK_ENTRY_ARRAY,
} from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";
import RscHbMultiSectionWrapper from "./components/rsc_hb_multi_section_wrapper/rsc_hb_multi_section_wrapper";
import RscHbSectionArticles from "./components/rsc_hb_section_articles/rsc_hb_section_articles";
import RscHbSectionEvents from "./components/rsc_hb_section_events/rsc_hb_section_events";
import RscHbSectionReports from "./components/rsc_hb_section_reports/rsc_hb_section_reports";

export default function TemplateRscHb(props) {
  const { story, pageContext, entryAssets } = usePageData(props);
  const { title, subtitle } = story;

  const {
    breadcrumbsItems,
    contactForm,
    lang,
    multiSectionTitles,
    navigationConfig,
    resourceHubCtaConfig,
    sections,
    subNavData,
  } = pageContext;

  const {
    openBankingSectionTitle,
    openBankingSectionSubtitle,
    primaryArticlesMultiSectionTitle,
    primaryArticlesMultiSectionSubtitle,
    secondaryArticlesMultiSectionTitle,
    secondaryArticlesMultiSectionSubtitle,
  } = multiSectionTitles;

  const {
    primaryArticleSectionObjectsArray,
    secondaryArticleSectionObjectsArray,
  } = sections;

  const { ctaNewsletter } = resourceHubCtaConfig || {};

  return (
    <TemplateGlobalEntry
      subNavData={subNavData}
      entryAssets={entryAssets}
      headerColor="blue"
      {...props}
    >
      <RscHbPageWrapper
        items={subNavData}
        lang={lang}
        level1ActiveItem="resource-hub"
        navigationConfig={navigationConfig}
      >
        <RscHbPageHeader
          breadcrumbsItems={breadcrumbsItems}
          title={title}
          description={subtitle}
        />

        {/* ————— HERO CONTENT SECTION ————— */}
        {/* Featured articles sections */}
        <RscHbSectionArticles content={sections.featuredItem} />

        {/* Popular articles sections */}
        <RscHbSectionArticles content={sections.popularArticles} />

        {/* Events */}
        <RscHbSectionEvents content={sections.events} />

        {/* Newsletter */}
        <RscHbCtaNewsletter {...ctaNewsletter} />

        {/* Reports */}
        <RscHbSectionReports content={sections.reports} />

        {/* ————— OPEN BANKING MULTI-SECTION ————— */}
        <RscHbMultiSectionWrapper
          title={openBankingSectionTitle}
          subtitle={openBankingSectionSubtitle}
        >
          <RscHbSectionArticles content={sections.openBankingArticles} />
          <RscHbSectionReports content={sections.openBankingGuides} />
        </RscHbMultiSectionWrapper>

        {/* ————— PRIMARY ARTICLES MULTI-SECTION ————— */}
        <RscHbMultiSectionWrapper
          title={primaryArticlesMultiSectionTitle}
          subtitle={primaryArticlesMultiSectionSubtitle}
        >
          {primaryArticleSectionObjectsArray?.length > 0 &&
            primaryArticleSectionObjectsArray.map((currentSection) => {
              return (
                <RscHbSectionArticles
                  key={currentSection.title}
                  {...{ content: currentSection }}
                />
              );
            })}
        </RscHbMultiSectionWrapper>

        {/* ————— SECONDARY ARTICLES MULTI-SECTION ————— */}
        <RscHbMultiSectionWrapper
          title={secondaryArticlesMultiSectionTitle}
          subtitle={secondaryArticlesMultiSectionSubtitle}
        >
          {secondaryArticleSectionObjectsArray?.length > 0 &&
            secondaryArticleSectionObjectsArray.map((currentSection) => {
              return (
                <RscHbSectionArticles
                  key={currentSection.title}
                  {...{ content: currentSection }}
                />
              );
            })}
        </RscHbMultiSectionWrapper>
      </RscHbPageWrapper>
      {/* ————— CONTACT FORM ————— */}
      <RscHbInPageContactForm lang={lang} {...contactForm} />
    </TemplateGlobalEntry>
  );
}

TemplateRscHb.propTypes = {
  data: PropTypes.shape({
    story: STORYBLOK_ENTRY,
    items: STORYBLOK_ENTRY_ARRAY,
    assets: PropTypes.shape({
      nodes: PropTypes.arrayOf(ASSET),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    breadcrumbsItems: PropTypes.arrayOf(PropTypes.shape({})),

    multiSectionTitles: PropTypes.shape({
      openBankingSectionTitle: PropTypes.string,
      openBankingSectionSubtitle: PropTypes.string,
      primaryArticlesMultiSectionTitle: PropTypes.string,
      primaryArticlesMultiSectionSubtitle: PropTypes.string,
      secondaryArticlesMultiSectionTitle: PropTypes.string,
      secondaryArticlesMultiSectionSubtitle: PropTypes.string,
    }),

    contactForm: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
    lang: PropTypes.string,

    sections: PropTypes.shape({
      events: RESOURCE_HUB_SECTION,
      featuredItem: RESOURCE_HUB_SECTION,
      openBankingArticles: RESOURCE_HUB_SECTION,
      openBankingGuides: RESOURCE_HUB_SECTION,
      popularArticles: RESOURCE_HUB_SECTION,
      reports: RESOURCE_HUB_SECTION,
      primaryArticleSectionObjectsArray:
        PropTypes.arrayOf(RESOURCE_HUB_SECTION),
      secondaryArticleSectionObjectsArray:
        PropTypes.arrayOf(RESOURCE_HUB_SECTION),
    }),

    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

// TODO: get page specific metadata here
export const query = graphql`
  query resourceHubQuery(
    $id: String
    $langRegex: String
    $allCoverIDs: [String]
  ) {
    ...global
    ...resourceHubStory
    ...resourceHubCoverAssets
  }
`;
