import React from "react";

import RscHbCardReport from "~components/molecules/rsc_hb_card/rsc_hb_card_report/rsc_hb_card_report";

import { RESOURCE_HUB_SECTION } from "../../../../prop_types";
import { GRID_SPLIT_REPORTS } from "../../grid_splits";
import RscHbSectionInner from "../rsc_hb_section_inner/rsc_hb_section_inner";

export default function RscHbSectionReports({ content }) {
  if (
    content?.title &&
    content?.link &&
    content?.linkTitle &&
    content?.items?.length > 0
  ) {
    return (
      <RscHbSectionInner
        title={content.title}
        link={content.link}
        linkTitle={content.linkTitle}
        gridSplit={GRID_SPLIT_REPORTS}
      >
        {content.items.map((item) => {
          return <RscHbCardReport {...item} key={item.title} block />;
        })}
      </RscHbSectionInner>
    );
  }
  return null;
}

RscHbSectionReports.propTypes = {
  content: RESOURCE_HUB_SECTION.isRequired,
};
