import React from "react";

import RscHbCardArticle from "~components/molecules/rsc_hb_card/rsc_hb_card_article/rsc_hb_card_article";
import RscHbCardArticleFeatured from "~components/molecules/rsc_hb_card/rsc_hb_card_article_featured/rsc_hb_card_article_featured";

import { RESOURCE_HUB_SECTION } from "../../../../prop_types";
import { GRID_SPLIT_ARTICLES, GRID_SPLIT_EVENTS } from "../../grid_splits";
import RscHbSectionInner from "../rsc_hb_section_inner/rsc_hb_section_inner";

export default function RscHbSectionArticles({ content }) {
  if (
    content?.title &&
    content?.link &&
    content?.linkTitle &&
    content?.items?.length > 0
  ) {
    if (content.items.length === 1) {
      return (
        <RscHbSectionInner
          gridSplit={GRID_SPLIT_EVENTS}
          link={content.link}
          linkTitle={content.linkTitle}
          title={content.title}
        >
          <RscHbCardArticleFeatured {...content.items[0]} />
        </RscHbSectionInner>
      );
    }
    return (
      <RscHbSectionInner
        title={content?.title}
        link={content?.link}
        linkTitle={content?.linkTitle}
        gridSplit={GRID_SPLIT_ARTICLES}
      >
        {content?.items.map((item) => {
          return <RscHbCardArticle {...item} key={item.title} block />;
        })}
      </RscHbSectionInner>
    );
  }
  return null;
}

RscHbSectionArticles.propTypes = {
  content: RESOURCE_HUB_SECTION.isRequired,
};
