import { format } from "date-fns";
import getTimezoneOffsetDate from "../get_timezone_offset_date/get_timezone_offset_date";

const TIME_FORMAT = "h:mmaaaaa'm'";

export default function formatTime(utcDateString, timezone = true) {
  // Use js Date object with time offset vs environment timezone
  const offsetTime = getTimezoneOffsetDate(utcDateString);
  const formattedTime = format(offsetTime, TIME_FORMAT);

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  if (timezone) {
    return `${formattedTime} (${timeZone})`;
  }
  return formattedTime;
}
