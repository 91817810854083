import React, { useState } from "react";
import PropTypes from "prop-types";
import { LINK, MEDIA } from "../../../../prop_types";
import EventDate from "../../event_date/event_date";
import getEventCardLink from "../helper_functions/get_event_card_link/get_event_card_link";
import RscHubCardCoverImage from "../rsc_hb_card_cover_image/rsc_hb_card_cover_image";
import RscHbCardInner from "../rsc_hb_card_inner/rsc_hb_card_inner";
import RscHbCardWrapper from "../rsc_hub_card_wrapper/rsc_hub_card_wrapper";

function RscHbCardEvent({
  cover,
  date,
  subtitle,
  registrationLink,
  link: pageLink,
  location,
  title,
}) {
  const [isActive, setIsActive] = useState(false);

  const link = getEventCardLink({
    registrationLink,

    pageLink,
  });

  return (
    <RscHbCardWrapper
      hasCard
      link={link}
      isActive={isActive}
      setIsActive={setIsActive}
      data-testid="rsc-hb-card-event"
    >
      <RscHubCardCoverImage roundTop cover={cover} isActive={isActive} />
      <RscHbCardInner addPadding {...{ title }}>
        <EventDate {...{ date, location, subtitle }} />
      </RscHbCardInner>
    </RscHbCardWrapper>
  );
}

RscHbCardEvent.defaultProps = {
  cover: undefined,
  endDate: null,
  location: null,
  subtitle: null,
  tags: null,
  videoLink: null,
  link: null,
  registrationLink: null,
};

RscHbCardEvent.propTypes = {
  cover: MEDIA,
  date: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  location: PropTypes.oneOfType([
    PropTypes.shape({
      firstLine: PropTypes.string,
      secondLine: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  registrationLink: LINK,
  link: LINK,
  subtitle: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  videoLink: LINK,
};

export default RscHbCardEvent;
