import { parseISO, compareAsc } from "date-fns";

export default function isDatePast(dateString) {
  const startDateParsed = parseISO(dateString);
  const now = new Date();
  const isPast = compareAsc(startDateParsed, now);
  if (isPast === -1) {
    return true;
  }
  return false;
}
