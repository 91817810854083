import PropTypes from "prop-types";
import React, { useState } from "react";

import { formatDate } from "~utils/date_utils";

import { LINK, MEDIA } from "../../../../prop_types";
import RscHubCardCoverImage from "../rsc_hb_card_cover_image/rsc_hb_card_cover_image";
import RscHbCardInner from "../rsc_hb_card_inner/rsc_hb_card_inner";
import RscHbCardWrapper from "../rsc_hub_card_wrapper/rsc_hub_card_wrapper";

function RscHbCardArticleFeatured({ cover, date, link, subtitle, title }) {
  const [isActive, setIsActive] = useState(false);

  let metadata = [];
  if (date) {
    metadata = [formatDate(date)].filter(Boolean);
  }

  return (
    <RscHbCardWrapper
      isLandscape
      link={link}
      isActive={isActive}
      setIsActive={setIsActive}
      data-testid="rsc-hb-card-article-featured"
    >
      <RscHubCardCoverImage
        hasCard
        roundTop
        roundBottom
        cover={cover}
        isActive={isActive}
      />

      <RscHbCardInner large {...{ metadata, subtitle, title }} />
    </RscHbCardWrapper>
  );
}

RscHbCardArticleFeatured.defaultProps = {
  author: null,
  category: null,
  cover: undefined,
  date: "",
  subtitle: "",
};

RscHbCardArticleFeatured.propTypes = {
  author: PropTypes.string,
  category: PropTypes.string,
  cover: MEDIA,
  date: PropTypes.string,
  link: LINK.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default RscHbCardArticleFeatured;
