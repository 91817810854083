import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import SVGIcon from "~components/utils/svg_icon/svg_icon";
import formatDate from "~utils/date_utils/format_date/format_date";
import formatTime from "~utils/date_utils/format_time/format_time";
import { itemStyle, wrapperStyle } from "./event_date.module.scss";

function EventDate({ date, location, iconSize }) {
  const wrapperDynamicClassName = classNames(wrapperStyle, {});

  return (
    <div data-testid="event-date" className={wrapperDynamicClassName}>
      {date && (
        <div data-testid="event-date-section" className={itemStyle}>
          <SVGIcon
            icon="icon_events"
            color="azure"
            width={iconSize}
            height={iconSize}
          />
          <time data-testid="event-date-section-time" dateTime={date}>
            {formatDate(date)} {formatTime(date)}
          </time>
        </div>
      )}
      {location && (
        <div data-testid="event-location-section" className={itemStyle}>
          <SVGIcon
            icon="icon_location"
            color="azure"
            width={iconSize}
            height={iconSize}
          />
          <div data-testid="event-location-section-text">{location}</div>
        </div>
      )}
    </div>
  );
}

EventDate.propTypes = {
  date: PropTypes.string,
  location: PropTypes.string,
  iconSize: PropTypes.number,
};

EventDate.defaultProps = {
  date: "02 Jun 2021",
  location: "online",
  iconSize: 28,
};

export default EventDate;
