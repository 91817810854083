import React, { useState } from "react";
import PropTypes from "prop-types";
import { formatDate } from "~utils/date_utils";
import { LINK, MEDIA } from "../../../../prop_types";
import RscHubCardCoverImage from "../rsc_hb_card_cover_image/rsc_hb_card_cover_image";
import RscHbCardInner from "../rsc_hb_card_inner/rsc_hb_card_inner";
import RscHbCardWrapper from "../rsc_hub_card_wrapper/rsc_hub_card_wrapper";

function RscHbCardArticle({ cover, date, link, subtitle, title }) {
  const [isActive, setIsActive] = useState(false);

  let metadata = [];
  if (date) {
    metadata = [formatDate(date)].filter(Boolean);
  }

  return (
    <RscHbCardWrapper
      hasCard
      link={link}
      isActive={isActive}
      setIsActive={setIsActive}
      data-testid="rsc-hb-card-article"
    >
      <RscHubCardCoverImage roundTop cover={cover} isActive={isActive} />
      <RscHbCardInner addPadding {...{ metadata, subtitle, title }} />
    </RscHbCardWrapper>
  );
}

RscHbCardArticle.defaultProps = {
  cover: undefined,
  date: "",
  subtitle: "",
};

RscHbCardArticle.propTypes = {
  cover: MEDIA,
  date: PropTypes.string,
  link: LINK.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default RscHbCardArticle;
