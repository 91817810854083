function getTodayUtcDateString() {
  const today = new Date();
  const todayDateISOString = today.toISOString().split("T")[0];
  const todayTimeISOString = today.toISOString().split("T")[1].split(".")[0];
  return `${todayDateISOString} ${todayTimeISOString}`;
}

module.exports = {
  getTodayUtcDateString,
};
