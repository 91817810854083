import PropTypes from "prop-types";
import React, { useState } from "react";

import { LINK, MEDIA } from "../../../../prop_types";
import RscHubCardCoverImage from "../rsc_hb_card_cover_image/rsc_hb_card_cover_image";
import RscHbCardInner from "../rsc_hb_card_inner/rsc_hb_card_inner";
import RscHbCardWrapper from "../rsc_hub_card_wrapper/rsc_hub_card_wrapper";

function RscHbCardReport({ cover, link, subtitle, title }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <RscHbCardWrapper
      link={link}
      isActive={isActive}
      setIsActive={setIsActive}
      data-testid="rsc-hb-report-card"
    >
      <RscHubCardCoverImage
        portrait
        hasCard
        roundTop
        roundBottom
        cover={cover}
        isActive={isActive}
      />
      <RscHbCardInner {...{ title, subtitle }} />
    </RscHbCardWrapper>
  );
}

RscHbCardReport.defaultProps = {
  category: null,
  ctaText: "Read more",
  cover: undefined,
  date: "",
  subtitle: "",
};

RscHbCardReport.propTypes = {
  category: PropTypes.string,
  ctaText: PropTypes.string,
  cover: MEDIA,
  date: PropTypes.string,
  link: LINK.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default RscHbCardReport;
