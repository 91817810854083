import React from "react";
import PropTypes from "prop-types";
import { LINK } from "../../../prop_types";
import ButtonTertiary from "../button/button_tertiary/button_tertiary";

export default function TextLink({
  title,
  isCentered,
  isActive,
  link,
  icon,
  margin,
  ...rest
}) {
  return (
    <ButtonTertiary
      color="yellow"
      trailingIcon={icon}
      isActive={isActive}
      isInteractive
      isCentered={isCentered}
      isFontBold
      isUnderlined
      isUppercase
      link={link}
      title={title}
      margin={margin}
      {...rest}
    />
  );
}

TextLink.defaultProps = {
  icon: "icon_chevron_right",
  title: "",
  link: null,
  isCentered: false,
  isActive: false,
  margin: {
    marginTop: "sm",
    marginBottom: "sm",
    marginLeft: "none",
    marginRight: "none",
  },
};

TextLink.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  link: LINK,
  isCentered: PropTypes.bool,
  isActive: PropTypes.bool,
  margin: PropTypes.shape({
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    marginTop: PropTypes.string,
  }),
};
